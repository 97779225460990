import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <img src={logo} className="App-logo" alt="logo" />
        </p>
      </header>
        <div className="App-body">
          <img src="https://s3.amazonaws.com/s3-djc/img/leverne/img_liquidsoap.jpg" className="landing-image" alt="logo" />
        </div>
        <div className="App-footer">
          <p>
            Copyright 2024 David Leverne
          </p>
        </div>
    </div>
  );
}

export default App;
